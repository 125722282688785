"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KycTypeMap = exports.KycType = exports.riskLevels = exports.propertyTitles = exports.valueTitles = exports.ADMIN_ROLE = void 0;
exports.ADMIN_ROLE = 'savingsAccountAdmin';
exports.valueTitles = {
  // PEP
  notPep: 'Inte PEP eller RCA',
  pep: 'Är PEP',
  rca: 'Anhörig till PEP',
  // pepTitles
  headOfGovernment: 'Stats eller regeringschef',
  parliamentMember: 'Riksdagsledamot/Parlamentsledamot',
  politicalPartyBoardMember: 'Ledamot i styrelse för politiskt parti',
  judgeOrLegalOfficial: 'Domare i rättsligt organ',
  governmentAuditor: 'Hög tjänsteman vid revisionsmyndighet',
  ambassadorOrEnvoy: 'Ambassadör eller beskickningschef',
  seniorMilitaryOfficer: 'Officerare inom Försvarsmakten',
  stateOwnedCompanyDirector: 'VD eller styrelseledamot i ett statsägt företag',
  internationalOrganizationLeader: 'Ledande befattning i en internationell organisation',
  // RCA Relations
  spouse: 'Make/maka',
  registeredPartner: 'Registrerad partner',
  cohabitant: 'Sambo',
  child: 'Barn',
  childSpouseCohabitant: 'Barns make/maka, sambo eller partner',
  parent: 'Förälder',
  knownCloseAssociate: 'Känd/Nära medarbetare',
  closeBusinessAssociate: 'Nära affärsförbindelse',
  // Tax residency
  workingAbroad: 'Arbetar utomlands',
  propertyAbroad: 'Fastighet eller lägenhet i utlandet',
  other: 'Annan',
  none: 'Ingen',
  // Interest
  '3': '3 månader',
  '3-auto': '3 månader med automatisk ombindning',
  '6': '6 månader',
  '12': '1 år',
  '18': '1,5 år',
  '24': '2 år',
  '36': '3 år',
  '48': '4 år',
  '60': '5 år'
};
exports.propertyTitles = {
  // Applicant Form
  name: 'Namn',
  personalNumber: 'Personnummer',
  countryCode: 'Landskod',
  phone: 'Mobilnummer',
  email: 'Email',
  postalCode: 'Postnummer',
  streetAdress: 'Gatuadress',
  streetNumber: 'Gatunummer',
  city: 'Ort',
  // Birth Form
  birthCountry: 'Födelseland',
  birthCity: 'Födelseort',
  citizenship: 'Medborgarskap',
  // PrimaryResidence Form
  primaryResidence: 'Primärt boende',
  // GreenCard Form
  greenCard: 'Green card',
  // AmericanCitizenship Form
  americanCitizenship: 'Amerikansk medborgare och/eller skatterättslig hemvist i USA',
  // TaxResidency Form
  taxResidencySwedish: 'Skatterättslig hemvist i Sverige',
  taxResidencyNotSwedish: 'Skatterättslig hemvist i andra länder',
  notUsaCitizenCheckbox: 'Intygar ej amerikansk medborgare',
  onlySwedenTaxCheckbox: 'Intygar skatterättslig hemvist i Sverige',
  taxResidencyCountry: 'Skatterättslig hemvist',
  taxResidencyRelation: 'Relation till landet',
  taxResidencyTin: 'Skatteregistreringsnummer/TIN',
  taxResidencyOtherRelation: 'Annan relation till landet',
  // Pep Form
  pepStatus: 'PEP',
  pepTitle: 'Arbetstitel',
  pepPosition: 'Arbetsposition',
  pepCountry: 'Land för PEP',
  pepRelation: 'Relation till PEP',
  pepOtherRelation: 'Ytterligare PEP relation',
  pepOtherRelationInfo: 'Ytterligare PEP relation info',
  pepOtherWork: 'Ytterligare PEP arbete',
  pepOtherWorkInfo: 'Ytterligare PEP arbete info',
  // BankRelationship Form
  openSavingsAccount: 'Vill öppna ett sparkonto',
  openFixedRateAccount: 'Vill öppna ett fasträntekonto',
  existingSavingsAccount: 'Använd befintligt konto',
  existingSavingsAccountNumber: 'Befintligt konto',
  interestRunningTime: 'Löptid fastränteplacering',
  interestPlacementAmount: 'Fastränteplacering belopp',
  wantToBeContacted: 'Vill bli kontaktad',
  // Income Form
  salary: 'Lön',
  pension: 'Pension',
  benefits: 'Bidrag eller arbetslöshetsersättning',
  studentAid: 'Studiebidrag och/eller studielån',
  soleProprietorship: 'Inkomst från enskild firma',
  capital: 'Kapital',
  other: 'Annan',
  // Savings Form
  savingsTotal: 'Totalt sparat kapital',
  savingsSource: 'Var kommer sparat kapital ifrån'
};
exports.riskLevels = {
  'normal': 'Normal',
  'high': 'Hög'
};
var KycType;
(function (KycType) {
  KycType[KycType["NONE"] = -1] = "NONE";
  KycType[KycType["BIRTH"] = 0] = "BIRTH";
  KycType[KycType["PRIMARY_RESIDENCE"] = 1] = "PRIMARY_RESIDENCE";
  KycType[KycType["GREEN_CARD"] = 2] = "GREEN_CARD";
  KycType[KycType["AMERICAN_CITIZENSHIP"] = 3] = "AMERICAN_CITIZENSHIP";
  KycType[KycType["TAX_RESIDENCY"] = 4] = "TAX_RESIDENCY";
  KycType[KycType["PEP"] = 5] = "PEP";
  KycType[KycType["BANK_RELATIONSHIP"] = 6] = "BANK_RELATIONSHIP";
  KycType[KycType["INCOME"] = 7] = "INCOME";
  KycType[KycType["SAVINGS"] = 8] = "SAVINGS";
})(KycType || (exports.KycType = KycType = {}));
exports.KycTypeMap = new Map([[KycType.BIRTH, 'birth'], [KycType.PRIMARY_RESIDENCE, 'primaryResidence'], [KycType.GREEN_CARD, 'greenCard'], [KycType.AMERICAN_CITIZENSHIP, 'americanCitizenship'], [KycType.TAX_RESIDENCY, 'taxResidency'], [KycType.PEP, 'pep'], [KycType.BANK_RELATIONSHIP, 'bankRelationship'], [KycType.INCOME, 'income'], [KycType.SAVINGS, 'savings']]);
