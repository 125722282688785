<h3>Välkommen</h3>
<form [formGroup]="welcomeForm" class="welcome-holder">
  <p>Välkommen till ansökan om att placera ditt sparande hos
    Sparbanken Syd!</p>

  <p>Vi kommer nu att ställa ett antal frågor till dig då vi som bank, enligt
    lag, måste ha en dokumenterad kännedom om dig och dina affärer.</p>

  <p>När din ansökan är godkänd så kommer vi att kontakta dig när produkter
    och
    tjänster är upplagda. </p>

  <p>Banken förbehåller sig rätten att neka öppning av konto.</p>

  <mat-checkbox formControlName="terms" color="primary" ngDefaultControl>
    Jag intygar att jag har tagit del av
    informationen om behandling av
    personuppgifter <a
    href="https://www.sparbankensyd.se/sv/om-oss/sakerhet-kundskydd/integritet-och-sekretess-gdpr"
    target="_blank">
    Läs mer</a>
  </mat-checkbox>

</form>
