import {Component, Input} from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatCheckbox } from '@angular/material/checkbox'

@Component({
  selector: 'spb-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  imports: [ReactiveFormsModule, MatCheckbox]
})
export class WelcomeComponent {
  @Input({required: true}) welcomeForm!: FormGroup
}
